import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppIds, AppRoutes } from '@app/app-routes';
import { BackendService } from '@app/backend/backend.service';
import { CaseStateService } from '@app/states/case/case-state.service';
import { OrganizationStateService } from '@app/states/organization/organization-state.service';
import { Organization } from '@app/states/organization/organization.model';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormFieldsLoaderService, FormType } from '@shared/ruv-forms/form-loader.service';
import cloneDeep from 'lodash.clonedeep';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, map, take, tap } from 'rxjs/operators';
import { Case, CaseType } from '@app/states/case/case.model';
import { CaseHelper } from '@app/states/case/case-helper';
import { TranslateService } from '@ngx-translate/core';
import { PdfLinkStateService } from '@app/states/case/kkink/pdf-link-state.service';

@Component({
  selector: 'ruv-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
  standalone: false,
})
export class CoreComponent {
  public form = new FormGroup({});
  public options: FormlyFormOptions = {
    formState: {},
  };
  public organization$!: Observable<Organization | undefined>;
  public model!: Case;
  public hasWelcomeText = true;
  public isKkink = false;
  public isGym = false;
  private configId: string | null;
  private caseHelper: CaseHelper<Case>;
  private pageSize = 1;

  private currentIndex = new BehaviorSubject<number>(0);
  private fieldsSubject = new Subject<FormlyFieldConfig[][]>();
  public fields$ = combineLatest({
    fieldsList: this.fieldsSubject.asObservable(),
    index: this.currentIndex.asObservable(),
  }).pipe(map(({ fieldsList, index }) => fieldsList[index]));

  private isSubmitPending = false;

  constructor(
    private logger: NGXLogger,
    private router: Router,
    private route: ActivatedRoute,
    private backend: BackendService,
    private formFieldsLoader: FormFieldsLoaderService,
    private caseStateService: CaseStateService,
    private pdfLinkStateService: PdfLinkStateService,
    private translateService: TranslateService,
    organizationStateService: OrganizationStateService,
  ) {
    this.caseHelper = this.caseStateService.caseHelper;
    this.configId = this.route.snapshot.paramMap.get(AppIds.ConfigId);
    this.organization$ = organizationStateService.organization$;
    this.model = this.caseHelper.preProcessCase(cloneDeep(this.route.snapshot.data.caseObject));

    const journeyName = this.model.journeyName;
    if (journeyName) {
      this.logger.debug('Pre-processed case', this.model);

      const initialCase = cloneDeep<Case>(this.model);
      this.isKkink = journeyName === CaseType.KKINK;
      this.isGym = journeyName === CaseType.GYM;
      this.hasWelcomeText = !(this.isKkink || this.isGym);

      this.options = {
        ...this.options,
        formState: {
          initialModel: initialCase,
          caseHelper: this.caseHelper,
          next: () => this.onNext(),
          prev: () => this.onPrev(),
          translateService: this.translateService,
          organization: organizationStateService.value,
        },
      };

      const formsList = caseStateService.formsList;
      this.pageSize = formsList.length;
      this.loadForms(formsList, journeyName);
    } else {
      this.router.navigate([this.configId, AppRoutes.Error]);
    }
  }

  public onFormModelChanged(model: Case) {
    this.logger.debug('Model: ', model);
    this.logger.debug('Form: ', this.form);
    this.logger.debug('FormOptions', this.options);
  }

  public submit(caseObject: Case) {
    if (this.isSubmitPending) {
      return;
    }

    if (caseObject) {
      this.isSubmitPending = true;
      const newCase = this.caseHelper.postProcessCase(caseObject, this.options.formState.initialModel);
      this.backend
        .sendCase(newCase)
        .pipe(
          take(1),
          tap((response) => {
            if (response) {
              if (this.model.journeyName === CaseType.KKINK) {
                this.pdfLinkStateService.updatePdfLinks({
                  contractWasUpdated: response.contractWasUpdated,
                  getPDFUrl: response.getPDFUrl,
                  headPDFUrl: response.headPDFUrl,
                });
                caseObject.frontendOnlyFields = {
                  ...caseObject.frontendOnlyFields,
                  hasChanges: response.contractWasUpdated,
                };
              }
              this.finalizeModel(caseObject);
            }
          }),
          catchError((err) => {
            this.logger.error('Could not save case', err);
            return EMPTY;
          }),
          finalize(() => {
            this.isSubmitPending = false;
          }),
        )
        .subscribe();
    }
  }

  private onNext(): void {
    this.updatePageIndex(this.currentIndex.value + 1);
  }

  private onPrev(): void {
    this.updatePageIndex(this.currentIndex.value - 1);
  }

  private updatePageIndex(index: number): void {
    if (index >= 0 && index < this.pageSize) {
      this.currentIndex.next(index);
      this.scrollToTop();
    }
  }

  private scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
    });
  }

  private finalizeModel(caseObject: Case) {
    this.logger.debug('Saved case successfully', caseObject);
    this.caseStateService.setCase(caseObject);
    this.router.navigate([this.configId, AppRoutes.Success], { replaceUrl: this.isKkink });
  }

  private loadForms(formsList: FormType[], journeyName: CaseType) {
    const loadedForms = formsList.reduce((pre, type) => {
      return {
        ...pre,
        [type]: this.formFieldsLoader.loadFormById(type, journeyName).pipe(catchError(() => of(null))),
      };
    }, {});

    combineLatest(loadedForms).subscribe((forms: FormlyFieldConfig[][]) => {
      const listOfForms = Object.values(forms);

      this.fieldsSubject.next(listOfForms);
    });
  }
}
